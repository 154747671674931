.left_column {
    border-right: 1px solid #999999;
}

.summary_card {
    height: 25vh;
    margin-left: 25px;
    margin-right: 10px;
    margin-top: 20px;
    margin-bottom: 20px;
}

.embedding_card {
    height: 65vh;
    border-top: 1px solid #999999;
    position: relative;
}

.ranking_card {
    height: 90vh;
}

.embedding_wrapper_div {
    width: 100%;
    height: 68vh;
}

.embedding_control_wrapper_div {
    top: 10px;
    display: inline;
    position: absolute;
    pointer-events: none;
    padding-left: 10px;
}

.control_overlay_div {
    width: 30%;
    padding: 5;
    pointer-events: auto;
}
