.modal {
}

.card {
    opacity: 0.7;
    width: 20vw;
    cursor: pointer;
    
}

.card-img-top {
    width: 100%;
    height: 15vh;
    object-fit: cover;
}

.card:hover {
    opacity: 1;
    border-color: black;
    background-color: #f5f5f5;
}

.card-body {
    border-top: 1px solid #f2f2f2;
}
