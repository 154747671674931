.evaluation_content {
}

.menu_row {
    border-bottom: 2px solid #999999;
    padding-top: 5px;
}

.grey_text {
    color: #6c757d;
    margin-top: 2px;
    font-size: 12px;
}

.checkmark_div {
    width: 170px;
}

.rew_agg_dropdown_button {
}

.policy_view_div {
}

.embedding_wrapper_div {
    width: 100%;
    height: 67vh;
}

.embedding_control_wrapper_div {
    top: 110px;
    display: inline;
    position: absolute;
    pointer-events: none;
    padding-left: 10px;
}

.embedding_overlay_div {
    position: relative;
    display: inline-block;
    top: 110px;
    text-align: center;
    background-color: rgba(255, 255, 255, 0.9);
    height: 68vh;
}

.control_overlay_div {
    width: 30%;
    padding: 5;
    pointer-events: auto;
}

.box_container {
    width: 30%;
    aspect-ratio: 1 / 1;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 8%;
    grid-row-gap: 5%;
}

.box {
    padding: 20px;
    margin-bottom: 10px;
    border-radius: 10px;
    color: rgb(50, 50, 50);
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #999999;
    font-size: 20px;
    filter: grayscale(100%);
    font-family: sans-serif;
    background-position: center;
}

/* Apply gray filter over background image when hovering over the box */
.box:hover {
    cursor: pointer;
    filter: grayscale(0%);
}

.widget_column {
    border-left: 2px solid #999999;
}

.smaller_margin {
    margin: 2;
}

.expand_caret {
    margin-left: 5px;
    color: grey;
}

.embedding_slider_div {
    margin: auto;
    width: 50%;
}

.embedding_data_button {
    width: 200px;
    margin: 30 auto;
    display: block;
}

.top_checkpoint_control_div {
    height: '5vh';
    padding: 15px;
    border-bottom: #999999 2px solid;
}

.blurr_circle {
    fill-opacity: 0.5;
    filter: blur(5px);
}
